/* You can add global styles to this file, and also import other style files */
@import "./scss/custom.css";

.dropdown-menu > ul {
  padding: 0px;
  margin-bottom: 0px;
}

.dropdown-menu {
  transform: none !important;
  top: 36px !important;
}

.btn-no-border, div.dropdown > button {
  background-color: #fff;
  color: #212529;
  border: none;
}

.btn2 {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: cornflowerblue;
  border: 1px solid transparent;
  padding: 0.05rem 0.05rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dropdown-bg {
  display: inline-block;
  margin-left: 0.1em;
  margin-right: 0.5em;
  vertical-align: 0.255em;
  border-top: 0.1em solid;
  border-right: 0.1em solid transparent;
  border-bottom: 0.1em solid;
  border-left: 0.1em solid transparent;
}

.dropdown-item:focus, .dropdown-item:hover {
  background-color: #8bc48336 !important;
  cursor: pointer;
}

.dropdown-item.active, .dropdown-item:active {
  color: initial;
}

nav {
  margin-bottom: 24px;
}

th {
  white-space: nowrap;
  vertical-align: middle !important;
}

table {
  border-collapse: separate !important;
  border-spacing: 0 !important;
}

td {
  vertical-align: middle !important;
}

.col-grp-outer-left {
  border-bottom: 1px solid #dee2e6 !important;
  border-top: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
}

.col-grp-outer-right {
  border-bottom: 1px solid #dee2e6 !important;
  border-top: 1px solid #dee2e6 !important;
  border-left: none !important;
}

.col-grp-outer-left-right {
  border-bottom: none !important;
  border-top: none !important;
}

.material-icons, .material-icons-outlined {
  display: inline;
  color: #495057;
}

.material-icons.secondary, .material-icons-outlined.secondary {
  color: #78909c;
}

.material-icons.light, .material-icons-outlined.light {
  color: #bdbdbd;
}

.material-icons.md-18, .material-icons-outlined.md-18 { 
  font-size: 18px; 
}

.material-icons.md-24, .material-icons-outlined.md-24 { 
  font-size: 24px; 
}

.material-icons.md-32, .material-icons-outlined.md-32 { 
  font-size: 32px; 
}

.material-icons.md-48, .material-icons-outlined.md-48 { 
  font-size: 48px; 
  padding-right: 8px;
}

.material-icons.md-128, .material-icons-outlined.md-128 { 
  font-size: 128px; 
}

.inverted {
  color: white !important;
  background-color: #979797;
}

th.number, td.number {
  text-align: right;
}

.checked {
  background-color: #E8F5E9;
}

.unchecked {
  background-color: #FFEBEE;
}

.card {
  margin: auto;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0,0,0,.125);
}

.table-settings > * {
  margin-right: 12px;
  margin: 4px;
}

.table-settings {
  margin-bottom: 4px;
}

a {
  outline: none;
}

.align-sub {
  vertical-align: sub;
}

.hover-move:hover {
  cursor: move
}

.hover-pointer:hover {
  cursor: pointer
}

.hover-underline:hover {
  text-decoration: underline;
}

h4.title {
  margin-bottom: 2rem;
}

/**
 * sticky columns
 */
.sticky {
  background: #fff;
  position: sticky;
  z-index: 11;
}

.left-0 {
  left: 0;
}

.top-0 {
  top: 0px;
}

.top-1 {
  top: 32.43px;
}

.whole-row > td {
  position: sticky;
  z-index: 11;
}

.whole-row.top > td {
  top: 104.3px;
}

/** end sticky columns **/

tbody > tr:hover > td.align-top.position-sticky.bg-white {
  background-color: #ececec;
}

.whole-row > .sticky, .whole-row * {
  color: #495057;
  background-color: #e9ecef !important;
}

.whole-row *:hover {
  cursor: pointer;
}

tr:hover > td.align-top.position-sticky.bg-white {
  color: #212529 !important;
  background-color: rgb(236, 236, 236) !important;
  font-weight: 500;
}

tr:hover > td.hover-pointer.bg-white {
  color: #212529 !important;
  background-color: rgb(236, 236, 236) !important;
  font-weight: 500;
}

.badge {
  font-weight: 400;
  font-size: 0.6875rem;
}

.modal {
  overflow: auto;
}

.modal-dialog.modal-xl {
  max-width: none;
}

.border-default {
  border: 1px solid #dee2e6 !important;
}

.border-success {
  border: 1px solid #7acf8f !important;
}

.border-left-important {
  border-left: 4px solid #8bc483 !important;
}

.notes {
  border-left: 4px solid #495057 !important;
  border-right: 4px solid #495057 !important;
  vertical-align: top !important;
}

.font-smaller {
  font-size: smaller;
}

.font-smaller-bold {
  font-size: smaller;
  font-weight: 800;
}

.font-small {
  font-size: small;
}

.font-xx-large {
  font-size: xx-large;
}

th {
  font-weight: 500;
}

th.position-sticky.left-0.top-0.bg-white {
  z-index: 1010;
}

th.position-sticky.top-0.bg-white {
  z-index: 1000;
}

img {
  cursor: pointer;
}

img.img-thumb {
  object-fit: cover;
}

.upload, .download, .download-renders, .reload {
  margin-left: 0.5rem;
  cursor: pointer;
}

.upload i {
  margin-right: 0.2rem; 
  color: #8ac6d1;
}

.download i {
  margin-right: 0.2rem; 
  color: #ff9c91;
}

.download-renders i {
  margin-right: 0.2rem; 
  color: #b794f6;
}

.reload i {
  margin-right: 0.2rem; 
  cursor: pointer;  
  color: #6bcf92;
}

.white-space-pre {
  white-space: pre;
}

.initials {
  color: #fff;
  padding: 1px 10px;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 8px;
  margin-top: 2px;
  font-size: large;
}

button.close {
  opacity: 1.0;
}

button.close > span {
  font-weight: bold;
  font-size: x-large;
}

button.close > span.font-small {
  font-weight: bold;
  font-size: smaller;
}

.close-button {
  background: 0 0;
  border: 0;
  position: absolute;
  right: 8px;
  font-size: 1.75rem;
  margin: 0.5rem;
  cursor: pointer;
}

.close-button i {
  color: white !important;
}


.custom-file-label::after {
  font-weight: 400;
  background: #fff;
}

.custom-file-input {
  cursor: pointer;
}

.custom-file::hover {
  border: 1px red solid;
}

.align-super {
  vertical-align: super;
}

.modal-title {
  align-self: flex-end;
}

.img-btn {
  display: none;
}

.img-btn-container:hover .img-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.img-behind-view-btns {
  position: relative;
  left: -114px;
  z-index: -1;
}

.img-behind-view-btns-card-view {
  position: absolute;
  top: 20px;
  left: 1.2rem;
  z-index: 0;
}

.form-check-input {
  margin-top: 0.15rem;
}

.white-space-pre {
  white-space: pre;
}
